@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

.read-more {
    font-family: 'Source Sans Pro', sans-serif;
    border: 1px solid #301934;
    font-weight: 100;
    letter-spacing: 1px;
    padding: 7px 20px;
    background-color: black;
    color: white;
}


.read-more:hover {
    border: 1px solid #301934;
    letter-spacing: 1px;
    padding: 7px 20px;
    background-color: transparent;
    color: #301934;
    transition: 0.4s;
}


.card-title {
    font-family: 'Montserrat', sans-serif;
}


.complete-card {
    margin-bottom: 45px;
    
    
}


.custom-img {
    padding: 20px;
    border-radius: 5%;
}


.card-text {
    font-family: 'Montserrat', sans-serif;
   
    text-align: justify;
    hyphens: auto;
    word-spacing: 0.5px; /* Adjusted for better spacing */
}



.custom-card:hover {
    transform: scale(1.1);
    transition: 0.5s;
}


