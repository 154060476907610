/* Landing Image with About Heading */

.main-page {
    overflow-x: hidden;
}


.about-main {
    animation: fadeIn 3s ease;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.about-img-text {
    background-image: url('../images/about/about.jpg');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.about-heading {
    font-family: 'Montserrat', sans-serif;
    color: #301934;
}





/* About & Mission and vision */

.about-company-header {
    font-family: 'Montserrat', sans-serif;

}

.about-company-paragraph {
    font-family: 'Montserrat', sans-serif;
    /* letter-spacing: 1px; */
    text-align: justify;
    hyphens: auto;
    word-spacing: 1px;
}


.symbol {
    width: 100%; /* The image will take up 100% of the width of its parent container. */
    max-width:25rem; /* The image will not grow wider than 30 rem units. */
    height: auto; /* The height will adjust automatically to maintain the aspect ratio. */
}



.text-image img,
.text-image h2 {
    display: inline-block;
    vertical-align: middle;
    /* Adjust vertical alignment as needed */
}

.mission-vision {
    max-width: 100px;
    /* Adjust image size as needed */
    margin-right: 10px;
    /* Add spacing between image and heading */
}


@media (max-width: 600px) {

    .symbol {
        width: 10rem;
        margin-right: 50px;
    }

}

@media (max-width: 600px) {

    .symbol {
        width: 20rem;
        margin-right: 50px;
    }

}




/* Why choose us section */

.all {
    background-color: rgb(212, 220, 236);
    padding: 90px;
    /* Padding from the background */
}

.columns-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping to next line */
    justify-content: center;
    gap: 20px;
    /* Space between cards */
}

.col-all {
    flex: 1 1 calc(50% - 40px);
    /* Each column takes up 50% width minus gap */
    max-width: calc(50% - 40px);
    /* Max width is 50% minus gap */
    box-sizing: border-box;
    /* Ensure padding and border are included in width */
}

.cards {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 60px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    height: 32rem;
    overflow: hidden;
    /* Default: No scroll */
}

/* Hide scrollbar for mobile views */
.cards::-webkit-scrollbar {
    display: none;
}

.cards {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Apply overflow scroll for larger screens */
@media (min-width: 768px) {
    .cards {
        overflow: scroll;
        /* Enable scroll for larger screens */
    }
}




.why-choose {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    flex-shrink: 0;
}

.cards p {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    flex: 1;
    align-self: center;
    text-align: justify;
    hyphens: auto;
    word-spacing: 1px;


}





.cards:hover {
    transform: scale(1.1);
    transition: 0.5s;
}



.about-company-content {
    display: flex;
    flex-direction: column;
}

@media (max-width: 600px) {
    .col-all {
        max-width: 1500px;

    }

    .columns-container {
        display: flex;
        flex-wrap: wrap;
        /* Allows wrapping to next line */
        justify-content: center;
        gap: 40px;
        /* Space between cards */
    }

    .about-company-content {
        display: grid;
        grid-template-columns: 1fr;
        /* On small screens, make it a single column */
        gap: 10px;
        /* Adjust spacing between header and paragraph */
    }

    .about-company-paragraph {
        margin-top: 0;

    }

}


@media (min-width: 601px) and (max-width: 1000px) {
    .col-all {
        max-width: 140rem;
        padding: 20px 40px;

    }

}


.team-image {
    border: 1px solid #ddd;
    width: 29rem;
    /* border-radius: 25px; */

}

.why-choose {
    max-width: 16rem;
}


@media (min-width: 1400px) {
    .about-img-text {
        height: 55vh;
    }
}


.cards-ourteam {
    background: #efeff2b2;

    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 60px;
    text-align: center;
    box-sizing: border-box;
    width: 96%;

}


.text-ourteam {
    font-family: 'Montserrat', sans-serif;
    color: #301934;
    text-align: justify;
}


.animations {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.animations h2 {
    position: absolute;
    /* Position absolute to overlap */
    opacity: 0;
    /* Initially hide all h2 elements */
    animation: cycleH2 6s infinite;
    /* Apply cycleH2 animation */
    color: #301934;
    font-weight: bold;
}

/* Keyframes for cycleH2 animation */
@keyframes cycleH2 {

    0%,
    16.67% {
        opacity: 0;
        z-index: 1;
    }

    /* Hide and bring to front */
    16.67%,
    33.33% {
        opacity: 1;
        z-index: 0;
    }

    /* Show and send to back */
    33.33%,
    100% {
        opacity: 0;
        z-index: 0;
    }

    /* Hide */
}

.animations h2:nth-child(1) {
    animation-delay: 1s;
    /* No delay for the first h2 */
}

.animations h2:nth-child(2) {
    animation-delay: 3s;
    /* Delay the second h2 by 2 seconds */
}

.animations h2:nth-child(3) {
    animation-delay: 5s;
    /* Delay the third h2 by 4 seconds */
}


.text-whychoose {
    text-align: left;
}




/* Industries Section */

.cards-industries {
    text-decoration: none;
    background: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 30px 20px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    position: relative;
    overflow: scroll;

}



/* Scroll bar hide */

.cards-industries::-webkit-scrollbar {
    display: none;
}

.cards-industries {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}



.cards-industries:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    color: #5C4033;
    /* border-radius: 0px; */
    cursor: pointer;

}

.cards-industries p {
    color: #5C4033;
    margin-bottom: 0;
}


.back-color {
    background-color: #9ca4ce1f;
}


.industries-readmore {
    margin-left: 1.2rem;
    /* text-align: justify; */

    font-family: 'Montserrat', sans-serif;
}


.industries-learnmore {
    color: white;
}


.cards-industries:hover .industries-learnmore {
    color: rgb(209, 18, 18);
}


.industry-text {
    font-family: 'Montserrat', sans-serif;
    background-color: #f6f3f7;
    border-radius: 10px;
}



/* our team section */

.ourteam-background {
    background-image: url('../images/about/ourteam.jpg');
    background-size: cover;
    background-attachment: fixed; 
}


.cards-ourteam {
    /* Adjust margins, padding, and other styles as needed */
    padding: 20px;
    margin: 10px;
    text-align: left;
    /* Ensure text alignment */
}

.text-ourteam {
    /* Additional text styles */
    font-size: 16px;
    line-height: 1.5;
}


.mySlides {
    display: none;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.mySlides.fade-in {
    opacity: 1;
}

.arrow {
    color: rgb(10, 10, 26);
    font-size: 2rem;
    cursor: pointer;
}


.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active,
.dot:hover {
    background-color: #717171;
}


.icons {
    width: 100rem;
}

.ourteam-header {
    font-family: 'Montserrat', sans-serif;
}