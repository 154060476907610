

.gen-ai-image   {
    background-image: url('../images/genai/ai.gif');
    height: 65vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 
}



.ai-images  {
    width: 20rem;
    padding: 10px;
}


.ai-middle-image   {
    width: 20rem;
}


.ai-side-images   {
    width: 5rem;
    transition: transform 0.3s ease; 
}


.ai-side-images:hover   {
    cursor: pointer;
    transform: scale(1.5);
    
}


.ai-text-bg {
    background: linear-gradient(to top right, #bfc6ff 65%, white 90%);
    padding: 20px;
    border-radius: 0px; /* More rounded corners for a bubble effect */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Adding shadow for depth */
    border: 2px solid rgba(255, 255, 255, 0.8); /* Light border for a more defined look */
    position: relative;
}



.box-vertical {
    border: 1px solid #ddd;
    border-radius: 0px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
}


/* Media query for devices between 780px and 971px */


.service-images {
    width: 100%;
    height: auto;
    border-radius: 10px;
   
}


.keyplayers {
    width:6rem;
    transition: transform 0.3s ease; 
}


.keyplayers:hover   {
    cursor: pointer;
    transform: scale(1.5);
    
}

.key-plyaer-bg  {
    background: linear-gradient(135deg, antiquewhite 50%, rgb(182, 209, 240) 50%);
    background-attachment: fixed; 
}



.same-line-img  {
    display: flex;
    justify-content: center;
}



.typing {
    display: inline-block;
    overflow: hidden;
    border-right: .15em solid orange; 
    white-space: nowrap;
    animation: typing 3s steps(20, end), blink-caret .5s step-end infinite;
}

.cursor {
    animation: blink-caret .75s step-end infinite;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
}


/* End to end services */

.end-toend-service-image  {
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.whatisgenai-bg {
    position: relative;
    background-color: #bfc6ff; 
    overflow: hidden;
    background: linear-gradient(to bottom right, #bfc6ff 45%, white 60%);
    background-size: 200% 100%;
    background-attachment: fixed; 
}




.custom-list {
    list-style-type: none; /* Remove default bullets */
 
}

.custom-list li {
    margin-bottom: 20px;
}


.end-to-end-images   {
    width: 25rem;
}


.endtoend-ai-bg {
    position: relative;
    overflow: hidden;
    background-color: #bfc6ff; /* Fallback color */
    background: linear-gradient(to bottom right, #bfc6ff 45%, white 60%);
    background-size: 100% 100%; /* Ensuring the gradient covers the entire element */
    background-position: center; /* Center the gradient within the element */
    background-attachment: fixed; 
}


.key-services-bg   {
    position: relative;
    overflow: hidden;
    background-color: #bfc6ff; /* Fallback color */
    background: linear-gradient(to bottom right, #bfc6ff 45%, white 90%);
}




.end-end-wrapper {
    overflow: hidden; /* Ensures the image doesn't overflow the wrapper */
  }
  
  .end-end-wrapper .oracle-images {
    transition: transform 400ms;
    cursor: pointer;
  }
  
  .end-end-wrapper:hover .oracle-images {
    transform: scale(1.2); /* Scales the image to 120% on hover */
  }
  

/* Key use cases */


.key-use-image  {
    list-style: none; 
    position: relative; 
  
  }
  

.key-use-image li::before {
  content: '✨'; 
  color: blue; 
  position: absolute; 
  left: -0.5em; 
}
  


.key-use-image li {
    margin-bottom: 40px;
}




@media (max-width: 600px) {
    .whatisgen-image   {
        width: 15rem;

    }
  
  }
  



  /* EXplore Gen AI In landing Page */

  .explore-genai-bg  {
    background-size: cover;
    background-image: url('../images/genai/ai.gif');
    height: 30vh;
    background-attachment: fixed; 
    background-repeat: no-repeat;
  }


  
  @media (max-width: 600px) {
    .explore-genai-bg   {
        height: 15rem;

    }
  
  }
  



  .explore-button {
    font-family: 'Source Sans Pro', sans-serif;
    border: 1px solid #301934;
    font-weight: 100;
    letter-spacing: 1px;
    padding: 7px 20px;
    background-color: white;
    color: black;
}


.explore-button:hover {
    border: 1px solid #f6f4f7;
    letter-spacing: 1px;
    padding: 7px 20px;
    background-color: transparent;
    color: white;
    transition: 0.4s;
}

