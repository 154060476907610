


.staffaugmentation-image  {
    background-image: url('../../images/staffaugmentation/business-people-shaking-hands-finishing-up-meeting-new-manager-office.jpg');
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 
}



.vertical {
    width: 2px;
    height: 100px;
    background-color: #000;
    margin: 0 auto;
    transform: rotate(180deg);
  
}


hr {
    
    border: 1px solid #000;

}


