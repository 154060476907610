/* EPM Services  */

.epm-image {
    background-image: url('../../images/epm-main-images/epm-main.jpeg');
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    
}



/* .epm-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-color: rgba(171, 154, 182, 0.5);
    mix-blend-mode: multiply;
} */



.epm-service-image {
    background-image: url('../../images/epm-main-images/creative-flat-lay-photo-workspace-desk.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    background-attachment: fixed; 
   
}



.epm-card {
    background: #00000047;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    height: 15rem;
}


.epm-card:hover {
    transform: scale(1.05);
}


.epm-images {
    width: 5rem;
    margin-bottom: 10px;
    height: 4rem;
}

.text-center {
    text-align: center;
}

@media (max-width: 600px) {
    .epm-service-image {
        height: auto;
        padding: 10px;
    }
}



.epm-view {
    font-family: 'Source Sans Pro', sans-serif;
    border: 1px solid white;
    letter-spacing: 1px;
    padding: 7px 20px;
    background-color: transparent;
    color: white;
    transition: 0.4s;

}



.epm-view:hover {
    font-family: 'Source Sans Pro', sans-serif;
    border: 1px solid #301934;
    font-weight: 100;
    letter-spacing: 1px;
    padding: 7px 20px;
    background-color: black;
    color: white;
}



@media (min-width: 768px) and (max-width: 988px) {

    .epm-card {
        width: 11rem;
        height: 17rem;
        font-size: 1rem;

    }

}



/* Oracle EPM Services */
.oracle-image {
    background-image: url('../../images/epm-main-images/cloud-file-sharing-banner-background(2).jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    
}


.image-wrapper {
    position: relative;
    height: 20rem;
    width: 100%;
    overflow: hidden;
}

.image-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    background-color: rgba(129, 129, 231, 0.5);
    mix-blend-mode: multiply;
    border-radius: inherit;
}

.oracle-images {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.image-wrapper .oracle-images {
    transition: scale 400ms;
    cursor: pointer;
}

.image-wrapper:hover .oracle-images {
    scale: 120%;
}

.image-wrapper {
    animation: slideInFromLeft 1s ease;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}


.col-0_5 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
}

.epm-number {
    font-weight: 100;
    color: yellow;
    background-color: #301934;

}


.whyoracle {
    background-color: beige;
}



/* SAP EPM services */

.sap-image {
    background-image: url('../../images/epm-main-images/table-consulting-paperwork-professional-invest-executive.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    

}


/* .sap-image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 55vh;
    background-color: rgba(129, 129, 231, 0.5);
    mix-blend-mode: multiply;
    border-radius: inherit;
} */


/* Anaplan Epm  */

.anaplan-image {
    background-image: url('../../images/epm-main-images/freepik-export-20240701125737J2NJ.jpeg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


/* IBM EPM */

.ibm-image {
    background-image: url('../../images/epm-main-images/standard-quality-control-collage.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



/* Onestream EPM */

.onestream-image {
    background-image: url('../../images/epm-main-images/businessman-balanced-financial-foreign-graph-accounting.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



/* Insights EPM */

.insights-image {
    background-image: url('../../images/epm-main-images/saas-concept-collage.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



/* Tangetik */

.tangetik-image {
    background-image: url('../../images/epm-main-images/business-person-looking-finance-graphs(1).jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


/* Board */

.board-image {
    background-image: url('../../images/epm-main-images/intelligence.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
