

/* Finance & Banking */


.finance-banking-bg {
    background-image: url('../images/industries/banking.jpg');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.finance-header  {
    color: black;
}


/* Healthcare */

.healthcare-bg  {
    background-image: url('../images/industries/medical.jpg');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}


/* Retail */

.retail-bg {

    background-image: url('../images/industries/ecommerce.jpg');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}


/* Manufacturing */

.manufacturing-bg   {
    background-image: url('../images/industries/manufacturing.jpg');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

.manufacture-header  {
    color: rgb(252, 247, 247);
}

/* Energy */

.energy-bg  {
    background-image: url('../images/industries/energy.jpg');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

/* Telecommunications */

.tele-bg  {
    background-image: url('../images/industries/telecommunications.avif');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

/* Sectors */

.sectors-bg  {
    background-image: url('../images/industries/abstract-business-people-city-buildings.jpg');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

