
.contact-img-text {
    background-image: url('../images/contact.png');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    

}


.address {
    font-size: 1.1rem;
    font-family: 'Montserrat', sans-serif;
}

.contact-form {
    max-width: 100rem;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
 
}



label {
    margin-top: 15px;
    font-size: 14px;
    color: #333;
}

input[type='text'] {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    font-size: 16px;
    padding: 5px 0;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    transition: border-color 0.3s;
}

input[type='text']:focus {
    border-bottom-color: blue;
}


.social-images {
    width: 2rem;
    margin-right: 12px;
    cursor: pointer;
}

.contact-heading {
    font-family: 'Montserrat', sans-serif;
    color: rgb(245, 238, 238);
}



.ouraddress-bg   {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #9ca4ce1f;
}



.location {
    position: relative; /* Ensure relative positioning for the pseudo-element */
    display: inline-block; /* Ensure the element respects the width of its content */
  }
  
  .location::after {
    content: ''; /* Required to create the pseudo-element */
    display: block; /* Ensure the pseudo-element behaves as a block element */
    position: absolute; /* Position it relative to the .location element */
    left: 0; /* Align to the left edge */
    bottom: -6px; /* Position at the bottom edge */
    width: 100%; /* Make it span the full width of the .location element */
    height: 2px; /* Thickness of the underline */
    background-color: #02050a; /* Color of the underline */
    transition: width 0.3s ease; /* Smooth transition for hover effects */
    
  }
  
  .location:hover::after {
    width: 100%; /* Change width on hover (can be adjusted for effects) */
  }
  


#contact {
  background: linear-gradient(to bottom right, #bfc6ff 0%, white 50%, #bfc6ff 100%);
  padding: 40px;
  border-radius: 15% 0%;
  
}



  .contact-special-image {
 
    width: 100%;
    max-width: 30rem;
    /* height: 21rem; */
    border-radius: 25% 0%;
    padding: 20px;
  }

  @media (max-width: 768px) {
    .contact-img-text {
        text-align: center;
    }
    
    .contact-special-image {
        width: 100%; /* Makes the image responsive */
        height: auto;
    }
    
    .contact-form {
        width: 100%;
        padding: 0 15px; /* Add some padding on smaller screens */
    }
}
