/* ../../../css/services/data&analytics.css */


.analytics-image {
    background-image: url('../../images/data-analytics-main-images/medium-shot-man-wearing-vr-glasses.jpg');
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
	background-attachment: fixed;
}



.service-card {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	overflow: scroll;
  margin-bottom: 20px;

}

  
  .service-card::-webkit-scrollbar {
    display: none;
}

.service-card {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

  
  .service-card:hover {
	transform: translateY(-10px);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  

  .icon-wrapper {
	font-size: 2rem;
	color: #3498db;
	margin-bottom: 20px;
  }
  
  .solution-header {
	font-size: 1.5rem;
	color: #333;
	margin-bottom: 10px;
  }
  
  .text-solution {
	font-size: 1rem;
	color: #d11414;
  }
  
  .text-solution a {
	color: #e74c3c;
	text-decoration: none;
	font-weight: bold;
  }
  
  .text-solution a:hover {
	text-decoration: underline;
  }
  

  .solution-bg  {
    background: linear-gradient(135deg, antiquewhite 50%, rgb(182, 209, 240) 50%);
    background-attachment: fixed; 
  }

  