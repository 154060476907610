
.dgtransform-image {
    background-image: url('../../images/digitaltransform/Vector_2652.jpg');
    height: 60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 
}


.heading-bold {
    font-weight: bold;
}


.point-change  {
    list-style: none; 
    position: relative; 
  
  }
  

.point-change  li::before {
  content: '✅'; 
  color: blue; 
  position: absolute; 
  left: -0.5em; 
}