

.footer {
    font-family: 'Montserrat', sans-serif;
}


.footer-img {
    width: 12rem;
}



.footer-text {
    font-size:small;
}


.text-white:hover {
    color: rgb(236, 205, 24);

}

.footer-location {
    font-size: 13px;
}

.text-solution {
    font-size: 13px;
    color: white;
    text-align: left;
}


.text-solution:hover {
    cursor: pointer;
    color: yellow;
    
}


