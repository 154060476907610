/* CSS of each solutions(Predictive analytics */

.predictive-bg {
    background-image: url('../images/solutions/predictive.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 
}



.solutions-box {
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    flex-direction: column;
    
}


.services-bg {
    background-color: rgb(212, 220, 236)
}

/* CSS of each solutions(data analytics */

.data-analytics-bg {
    background-image: url('../images/solutions/data-analytics.avif');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 

}


/* CSS of each solutions(machine learning */


.machine-learning-bg {
    background-image: url('../images/solutions/ai-cloud-with-robot-face.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 
}


/* CSS of each solutions(Business intelligence */

.prescriptive-bg {
    background-image: url('../images/solutions/data-engineer.avif');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 

}



/* CSS of each solutions(Consulting strategy */
.consulting-strategy-bg {
    background-image: url('../images/solutions/consulting.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 

}



/* CSS of each solutions(Big Data)*/

.bigdata-bg {
    background-image: url('../images/solutions/bigdata.jpg');
    height: 55vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; 

}