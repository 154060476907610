@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');


.main-page {
  overflow-x: hidden;
}

/* For Navbar Responsiveness */
.custom-toggler {
  width: 30px;
  height: 30px;
  background-image: url('../images/dropdown.png');
  background-size: cover;
  color: #fff; 
  border-color: #007bff; 
}


.landing-image {
  background-image: url('../images/landing.jpg');
  width: 100%;
  height: 100vh;
  filter: blur(0px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; 
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 600px) {

  .navbar {
    display: flex;
    justify-content: space-between; /* Distribute space between logo and toggle button */
  }


  .navbar-toggler {
    margin-left: auto; /* Pushes the toggle button to the right */
   
  }
  
  .logo {
    width: 70%;

  }

  .landing-image  {
    width: 70rem;
    height: 40rem;
    overflow-x: hidden;
    background-attachment: scroll; 
    
  }

  .landing-image .box-intro{
    width: 23rem;
   
  }

  .landing-image .connect {
    width: 18rem;
  }
}


@media (min-width: 484px) and (max-width: 768px) {
  .logo {
    width: 70%;
  }

  .box {
    width: 12%;
  }

  .landing-image {
    width: 49rem;
    overflow-x: hidden;
    
  }
}

@media (min-width: 769px) and (max-width: 991px) {

  .landing-image {
    width : 100%;
    overflow-x: hidden;
    height: 43rem;
    
  }

}




.navbar-nav .nav-link {
  letter-spacing: 4px;
  font-size: 13px;
  margin-right: 30px;
  margin-left: 30px;
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  color: rgb(221, 207, 11);
}


.navbar-nav .nav-link:hover {
  color: #0b84e7;
}


.navbar-nav .except::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #0b84e7;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover::after {
  transform: scaleX(1);
}



.logo {
  width: 45%;
}



.box {
  width: 700px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 30px;
  background-color: transparent;
}


.connect {
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid #301934;
  /* border-radius: 6px; */
  font-weight: 100;
  letter-spacing: 1px;
  padding: 7px 20px;
  background-color: black;
  color: white;
}


.connect:hover {
  border: 1px solid #301934;
  letter-spacing: 1px;
  padding: 7px 20px;
  background-color: transparent;
  color: #301934;
  transition: 0.4s;
}

.landing-image.grayscale {
  filter: grayscale(100%);
}


.dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: black;
  color: white !important;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 170%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  border-radius: 0px;
}


.navbar-nav .dropdown-menu a {
  color: #fdfdfd;
  display: block;
  padding: 10px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}


.navbar-nav .dropdown-menu a:hover {
  background-color: #0b84e7;
  color: white;
}


.navbar-nav .dropdown-item {
  font-size: 13px;
}


@keyframes moveDown {
  0% {
    top: -10%;
  }

  100% {
    top: 40%;
  }
}





/* For large screens */
@media (min-width: 768px) {
  .navbar-nav .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    animation: moveDown 0.7s ease-in-out forwards;
  }
}

/* For small screens */
@media (max-width: 767px) {
  .navbar-nav .dropdown .dropdown-menu {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  
  .navbar-nav .dropdown.show .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    animation: moveDown 0.7s ease-in-out forwards;
  }
}




/* glowing genai */

.glowing {
  position: relative;
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

.glowing::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, transparent, rgba(0, 255, 255, 0.8), transparent);
  animation: pulsate 2s linear infinite;
}

@keyframes pulsate {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}


@font-face {
  font-family: 'Nekst';
  src: url('../project/fonts/Fontspring-DEMO-nekst-semibold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



.animated-text {
  letter-spacing: 2px;
  opacity: 0;
  transform: translateX(-20px);
  animation: slideIn 0.5s forwards;
  font-family: 'Nekst', sans-serif;
  color: #f3f4f7;

}



.box-intro {
  border: 0px solid black;
  border-radius: 5px;
  padding: 20px;
  background-color: #8414731a;
}


@media (max-width: 767px) {
  .box-intro {
    padding: 15px;
    width: 67%;
    background-color: #e60ec51a;
  }

  .landing-image {
    height: 40rem;
  }

  .animated-text {
    font-size: 1.5em;
  }


  .connect {
    width: 100%;
    font-size: 1em;
    padding: 10px;
  }
}


.animated-text:nth-child(1) {
  animation-delay: 0.5s;
}

.animated-text:nth-child(2) {
  animation-delay: 1s;
}

.animated-text:nth-child(3) {
  animation-delay: 1.5s;
}

.animated-text-welcome {
  color: #e7eb05;
  animation: fadeIn 4s ease;
  font-family: 'Montserrat', sans-serif;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}





/* Not Found */

.not-found-container {
  text-align: center;
  margin-top: 100px; /* Adjust as needed */
}

.not-found-title {
  font-size: 5rem;
  color: #f44336; /* Or any other color you prefer */
}


.not-found-text {
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

.not-found-description {
  font-size: 1.2rem;
  color: #757575;
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto; /* Center align if using a max-width */
  font-family: 'Montserrat', sans-serif;
}

/* Example button styling */
.not-found-button {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
  text-decoration: none; /* Remove underline from button if styled as link */
}

.not-found-button:hover {
  background-color: #0d8bf2;
}
